/* eslint @typescript-eslint/no-explicit-any: "off" */
import { useEffect } from 'react';

function useKeyDown(key: any, callback: Function, args: any[]): void {
  function handler(e: any): void {
    if (e.key === key) {
      callback();
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handler);
    return (): void => {
      window.removeEventListener('keydown', handler);
    };
  }, [...args]);
}
export default useKeyDown;
