import React from 'react';
import ClassName from '@utils/helpers/ClassName';
import SVG from '../SVG';
import './Popup.scss';

interface PopupProps {
  name?: string;
  id?: string;
  className?: string;
  isOpen?: boolean;
  bodyId?: string;
  bodyClassName?: string;
  onCloseClicked: () => void;
  children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = (props) => {
  const { name, id, className, isOpen, bodyId, bodyClassName, onCloseClicked, children } = props;

  return (
    <div
      id={id}
      className={ClassName.join(
        'popup',
        `popup--${name}`,
        className,
        ClassName.bind({
          'popup--open': isOpen,
        })
      )}
    >
      {/* eslint-disable */}
      <div role="button" tabIndex={0} className="popup__veil" onClick={onCloseClicked} />
      <div id={bodyId} className={ClassName.join('popup__body', bodyClassName)}>
        <button type="button" className="popup__close" onClick={onCloseClicked}>
          <SVG name="close" className="popup__close-icon" />
        </button>
        {isOpen && children}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  id: '',
  bodyId: '',
  isOpen: false,
};

export default Popup;
