import React from 'react';
import ClassName from '@utils/helpers/ClassName';
import ExtLink from '../ExtLink';

import Info from '../../assets/images/info.inline.svg';
import LinkedIn from '../../assets/images/linkedin.inline.svg';

import './MemberCard.scss';

interface PopupDataProps {
  image: string;
  name: string;
  title: string;
  bio: string;
}

interface MemberCardProps {
  name: string;
  title: string;
  image: string;
  bio: string;
  link?: string;
  mobileExpandLabel?: string;
  onClick: (data: PopupDataProps) => void;
}

const MemberCard: React.FC<MemberCardProps> = ({ name, title, image, bio, link, mobileExpandLabel, onClick }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) onClick({ image, name, title, bio });
  };

  return (
    <div
      className={ClassName.join(
        'card card--team',
        ClassName.bind({
          'card--has-popup': bio !== '',
        })
      )}
      onClick={() => onClick({ image, name, title, bio })}
      tabIndex={0}
      role="button"
      onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
    >
      <div className="card__image-container">
        <img src={image} alt={name} className="card__image" />
      </div>
      <div className="card__info">
        <h4 className="card__name">{name}</h4>
        <div className="card__job-title">{title}</div>
        {bio && <div className="link link--primary">{mobileExpandLabel}</div>}
      </div>
      {bio !== '' && (
        <div className="card__bio">
          <Info />
        </div>
      )}
      {link && (
        <ExtLink className="card__link" href={link} target="_blank">
          <LinkedIn />
        </ExtLink>
      )}
    </div>
  );
};

MemberCard.defaultProps = {
  mobileExpandLabel: 'Learn more',
};

export default MemberCard;
