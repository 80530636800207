import React from 'react';
import ClassName from '@utils/helpers/ClassName';

import './section.scss';

interface SectionProps {
  name: string;
  className?: string;
  full?: boolean;
  children: React.ReactNode;
  style?: object;
}

const Section: React.FC<SectionProps> = ({ className, name, children, full, style }) => (
  <section
    className={ClassName.join(
      'section',
      `section--${name.replace(/\s/g, '-')}`,
      ClassName.bind({
        'section--full': full,
      }),
      className
    )}
    style={style}
  >
    {children}
  </section>
);

export default Section;

Section.defaultProps = {
  full: false,
};
